.tabla-motivos {
    max-width: 100%;
    overflow-x: auto;
    padding: 0px;
  }
  
  .tabla-motivos h1 {
    margin-top: 0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #99235C;
  }
  
  .tabla-motivos .table-container {
    overflow-x: auto;
  }
  
  .tabla-motivos .table-container table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
  }
  
  .tabla-motivos .table-container thead {
    background-color: #99235C;
  }
  
  .tabla-motivos .table-container th {
    padding: 8px;
    text-align: left;
    font-weight: bold;
    color: #ffffff;
    white-space: nowrap;
  }
  
  .tabla-motivos .table-container td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .status-badge {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.85em;
    font-weight: bold;
  }
  
  .status-badge.active {
    background-color: #d4edda;
    color: #000000;
  }
  
  .status-badge.inactive {
    background-color: #f8d7da;
    color: #000000;
  }
  
  .edit-button {
    background-color: #99235C;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #8b2353;
  }
  
  .add-button {
    background-color: #99235C;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-button:hover {
    background-color: #8b2353;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left : 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 50%;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-header h2 {
    margin-top: 0;
  }

  .close-button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .close-button:hover {
    color: #8b2353;
  }

  .checkbox-container {
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    gap: 15px;
  }

  .cancel-button {
    background-color: #99235C;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .cancel-button:hover {
    background-color: #8b2353;
  }

  .save-button {
    background-color: #99235C;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .save-button:hover {
    background-color: #8b2353;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-content label {
    margin-bottom: 10px;
  }
  
  .modal-content input[type="text"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
  }
  
  .modal-content input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .modal-content button[type="submit"] {
    background-color: #99235C;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content button[type="submit"]:hover {
    background-color: #99235C;
  }
