.change-password-page .right-section{
  padding: 50px;
}

.change-password-page .change-box {
  color: white;
  width: 100%;
  max-width: 400px;
  padding-bottom: 30px;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 410px;
  overflow: hidden;
}

.login-box .change-password {
  width: 300px; 
  max-width: 300px; 
  height: 390px;
  max-height: 300px;
}
.change-password-page h3 {
  text-align: center;
  font-size: 1.5rem;
}

.change-password-page .password-requirements ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  margin-bottom: 1rem;
}
  
.change-password-page .form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.change-password-page .form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.change-password-page .form-group .input-group {
  position: relative;
}

.change-password-page .form-group .input-group input {
  padding-right: 30px;
}

.change-password-page .form-group .input-group .show-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.change-password-page .form-group .input-group .show-password svg {
  font-size: 18px;
  color: #99235C;
}

.change-password-page .submit-button {
  width: 50%; 
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  padding: 2px;
  border: none; 
  border-radius: 4px; 
  background-color: #fff; 
  color: #99235C; 
  cursor: pointer; 
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.change-password-page .submit-button:hover {
  background-color: #eee;
}

.change-password-page .error-message,
.change-password-page .success-message{
  text-align: center;
}

.change-box .link-button-change {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  color: #fff;
  text-decoration: none;
}

/* Estilos generales para pantallas pequeñas */
@media (max-width: 768px) {
  .change-password-page .right-section {
    padding: 20px; /* Reduce el padding para pantallas más pequeñas */
  }

  .change-password-page .change-box {
    height: auto; /* Permite que la caja ajuste su altura automáticamente */
    padding: 20px; /* Agrega un poco de padding interno */
  }

  .change-password-page .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }


  .change-password-page h3 {
    font-size: 1.3rem; /* Ajusta el tamaño del encabezado */
  }

  .change-password-page .password-requirements ul {
    font-size: 12px; /* Reduce el tamaño de la fuente para los requisitos de contraseña */
  }
}
