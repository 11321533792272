.reset-password-page .login-box {
  width: 100%;
  height: 65vh;
}

.reset-password-page .login-box h2 {
  margin: 0 0 15px 0; /* Reducir el margen del título */
  font-size: 1.8rem;
}

.reset-password-page .login-box .form-group {
  margin-bottom: 0; /* Reducir el espacio entre grupos */
  display: flex;
  flex-direction: column;
}

.reset-password-page .login-box .form-group label {
  margin-bottom: 0;
  font-size: 0.9rem; /* Reducir ligeramente el tamaño de las etiquetas */
}

.reset-password-page .login-box .form-group input {
  margin-top: 0;
  height: 35px; /* Altura específica para los inputs */
}

.reset-password-page .login-box ul {
  margin: 5px 0;
  padding-left: 20px; /* Reducir el padding de la lista */
  list-style: none;
}

.reset-password-page .login-box ul li {
  font-size: 0.85rem; /* Reducir el tamaño del texto en los requisitos */
  margin: 0;
}


.reset-password-page .login-box .submit-button {
  margin-top: 10px;
  margin-bottom: 0;
}

.submit-button:disabled,
.cancel-button-password:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #cccccc;
}

.reset-password-page .login-box .cancel-button-password {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: white;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
}

.reset-password-page .login-box .link-button{
  margin-top: 0;
}

.reset-password-page .login-box .input-container {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.reset-password-page .login-box .input-container input {
  flex: 1;
  height: 35px;
}

.reset-password-page .login-box .password-icon {
  margin-left: -30px; /* Para mover el ícono dentro del input */
  cursor: pointer;
  color: #99235C;
  padding: 0 8px; /* Dar algo de espacio para hacer click */
}

/* Responsividad */
/* Pantallas medianas (tablets) */
@media (max-width: 768px) {
  .reset-password-page .login-box {
      padding: 40px; /* Ajustar padding para tablets */
      max-width: 80%; /* Ajustar el ancho al 80% de la pantalla */
  }

  .reset-password-page .login-box h2 {
      font-size: 1.8rem; /* Reducir el tamaño de la fuente para tablets */
  }

  .reset-password-page .login-box button[type="submit"] {
      font-size: 0.9rem; /* Reducir el tamaño del botón */
  }
}

/* Pantallas pequeñas (móviles) */
@media (max-width: 480px) {
  .reset-password-page .login-box {
      padding: 20px; /* Reducir padding para móviles */
      max-width: 95%; /* Ajustar el ancho al 95% de la pantalla */
  }

  .reset-password-page .login-box h2 {
      font-size: 1.5rem; /* Reducir el tamaño del título en móviles */
      margin-top: 0; /* Eliminar margen superior extra */
  }

  .reset-password-page .login-box .form-group input {
      font-size: 1rem; /* Ajustar el tamaño de los campos de texto */
  }

  .reset-password-page .login-box button[type="submit"] {
      font-size: 0.85rem; /* Reducir el tamaño del botón para móviles */
      width: 100%; /* Hacer que el botón ocupe el 100% del ancho */
  }

  .reset-password-page .login-box {
      margin: 0; /* Eliminar márgenes laterales */
  }
}
