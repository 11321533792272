
.comunas-page-title {
  text-align: center;
  margin-bottom: 25px;
  color: #333;
  font-size: 24px;
}

.comunas-tab-content {
  display: flex;
  flex-direction: column;
}

.region-selector-container {
  margin-bottom: 20px;
}

.region-selector {
  display: flex;
  flex-direction: column;
}

.region-selector label {
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.region-selector select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.promotions-list-table-container {
  flex: 2;
  border-radius: 8px;
  padding: 20px;
  box-shadow: none;
  overflow-x: auto;
  max-height: 400px;
  overflow-y: auto;
}

.promotions-list-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
}

.promotions-list-table th, 
.promotions-list-table td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
  transition: background-color 0.2s ease;
}

.promotions-list-table th {
  background-color: #f0f0f0;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.promotions-list-table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.promotions-list-table tr:hover {
  background-color: #f0f0f0;
}

.form-section {
  flex: 1;
  border-radius: 8px;
  padding: 25px;
  box-shadow: none;
}

.comunas-tarifas-form {
  display: flex;
  flex-direction: column;
}

.comunas-tarifas-field-group {
  margin-bottom: 20px;
}

.comunas-tarifas-field-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.comunas-tarifas-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.comunas-tarifas-field:focus {
  outline: none;
  border-color: #99235C;
  box-shadow: 0 0 0 2px rgba(153, 35, 92, 0.1);
}

.comunas-tarifas-submit-button {
  width: 100%;
  background-color: #99235C;
  color: white;
  border: none;
  padding: 12px 15px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.comunas-tarifas-submit-button:hover {
  background-color: #b93775;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.comunas-tarifas-submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .promotions-list-table-container,
  .form-section {
    flex: 1;
  }
}


/* Tarifas */
.tarifas-container {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
}

.tarifas-page-title {
  text-align: center;
  color: #99235C;
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.tarifas-tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #e0e0e0;
  overflow-x: auto;
  white-space: nowrap;
}

.tarifas-tab {
  padding: 5px 1px;
  margin: 0 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
}

.tarifas-tab:hover {
  color: #99235C;
}

.tarifas-tab.active {
  color: #99235C;
  font-weight: 600;
}

.tarifas-tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #99235C;
}

.tarifas-tab-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 30px;
}

/* Estilos para lista de promociones */
.promotions-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.promotions-list-search {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.promotions-search-input {
  width: 100%;
  max-width: 500px;
  padding: 12px 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.promotions-list-table-container {
  overflow-x: auto;
}

.promotions-list-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.promotions-list-table thead {
  background-color: #f8f9fa;
}

.promotions-list-table th,
.promotions-list-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.promotions-list-table th {
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.promotions-list-table tr:hover {
  background-color: #f5f5f5;
}

.promotions-list-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.promotions-pagination-button {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.promotions-pagination-button.active {
  background-color: #99235C;
  color: white;
  border-color: #99235C;
}

/* Estilos para sección de montos de instalación */
.tarifas-installation-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.tarifas-installation-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.tarifas-section-title {
  color: #99235C;
  border-bottom: 2px solid #99235C;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
}

/* Responsive */
@media (max-width: 768px) {
  .tarifas-tabs-container {
    flex-direction: column;
    align-items: stretch;
  }

  .tarifas-tab {
    text-align: center;
    margin: 5px 0;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
  }

  .tarifas-tab.active::after {
    display: none;
  }

  .tarifas-tab.active {
    background-color: #f8f8f8;
  }

  .tarifas-installation-container {
    grid-template-columns: 1fr;
  }
}

/* Estilos para formularios dentro de las secciones */
.tarifas-installation-section .comunas-tarifas-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.comunas-tarifas-field-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.comunas-tarifas-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.comunas-tarifas-submit-button {
  background-color: #99235C;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.comunas-tarifas-submit-button:hover {
  background-color: #7a1c4a;
}

/* Mantén todo el CSS anterior y añade/modifica estas secciones */

.comunas-container,
.tarifas-container {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.comunas-page-title,
.tarifas-page-title {
  text-align: center;
  color: #99235C;
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: 600;
}

.comunas-tabs-container,
.tarifas-tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #e0e0e0;
  overflow-x: auto;
  white-space: nowrap;
}

.comunas-tab,
.tarifas-tab {
  padding: 10px 15px;
  margin: 0 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.comunas-tab:hover,
.tarifas-tab:hover {
  color: #99235C;
}

.comunas-tab.active,
.tarifas-tab.active {
  color: #99235C;
  font-weight: 600;
}

.comunas-tab.active::after,
.tarifas-tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #99235C;
}

.comunas-tab-content,
.tarifas-tab-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 30px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.region-selector,
.tarifas-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.region-selector label,
.tarifas-selector label {
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.region-selector select,
.tarifas-selector select {
  width: 100%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.region-selector select:focus,
.tarifas-selector select:focus {
  border-color: #99235C;
  outline: none;
  box-shadow: 0 0 0 2px rgba(153, 35, 92, 0.1);
}

/* Consistencia en formularios */
.comunas-tarifas-form,
.tarifas-installation-section .comunas-tarifas-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comunas-tarifas-field-group,
.tarifas-installation-section .comunas-tarifas-field-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comunas-tarifas-field-group label,
.tarifas-installation-section .comunas-tarifas-field-group label {
  font-weight: 500;
  color: #333;
}

.comunas-tarifas-field,
.tarifas-installation-section .comunas-tarifas-field {
  width: 100%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.comunas-tarifas-field:focus,
.tarifas-installation-section .comunas-tarifas-field:focus {
  border-color: #99235C;
  outline: none;
  box-shadow: 0 0 0 2px rgba(153, 35, 92, 0.1);
}

.comunas-tarifas-submit-button,
.tarifas-installation-section .comunas-tarifas-submit-button {
  background-color: #99235C;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.comunas-tarifas-submit-button:hover,
.tarifas-installation-section .comunas-tarifas-submit-button:hover {
  background-color: #b93775;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .comunas-container,
  .tarifas-container {
    width: 100%;
    padding: 15px;
  }

  .content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .comunas-tabs-container,
  .tarifas-tabs-container {
    flex-direction: column;
    align-items: stretch;
  }

  .comunas-tab,
  .tarifas-tab {
    text-align: center;
    margin: 5px 0;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
  }

  .comunas-tab.active::after,
  .tarifas-tab.active::after {
    display: none;
  }

  .comunas-tab.active,
  .tarifas-tab.active {
    background-color: #f8f8f8;
  }
}

.selected-row {
  background-color: #f0f0f0;
}

.select-company-btn {
  background-color: #99235C;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-company-btn:hover {
  background-color: #b93775;
}

.obtener-empresas-details {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}